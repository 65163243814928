<template>
  <aside class="left-sidebar">
    <div class="scroll-sidebar">
      <div class="user-profile">
        <div class="user-pro-body">
          <div>
            <img
              :src="
                user.avatar
                  ? uploader(user.avatar)
                  : baseUrl + '/img/avatar-noimage.jpg'
              "
              alt="user-img"
              class="img-circle"
            />
          </div>
          <div class="dropdown">
            <a
              href="javascript:void(0)"
              class="dropdown-toggle u-dropdown link hide-menu"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ user.fullName }}<span class="caret"></span>
            </a>
            <div class="dropdown-menu animated flipInY">
              <div class="dropdown-divider"></div>
              <!-- <a 
              href="javascript:void(0)" 
              class="dropdown-item">
              <i class="ti-settings"></i> Account Setting
            </a> -->
              <div class="dropdown-divider"></div>
              <a @click="doLogout" href="javascript::" class="dropdown-item">
                <i class="fa fa-power-off"></i> Logout
              </a>
            </div>
          </div>
        </div>
      </div>
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <template v-for="(val, key) in menuData">
            <li
              v-if="val.head"
              :key="key"
              class="nav-small-cap text-uppercase font-size-xs line-height-xs"
            >
              --- {{ val.head }}
            </li>
            <li
              v-else-if="isShowParentMenu(val)"
              :key="key"
            >
              <a
                class="has-arrow waves-effect waves-dark"
                href="javascript:void(0)"
                aria-expanded="false"
              >
                <i :class="val.icon"></i>
                <span class="hide-menu">
                  {{ val.name }}
                  <!-- <span class="badge badge-pill badge-cyan ml-auto">
                  4
                </span> -->
                </span>
              </a>
              <ul aria-expanded="false" class="collapse">
                <template v-for="(val2, key2) in val.child">
                  <li v-if="val2.isPermit" :key="key2">
                    <router-link
                      :to="{ name: val2.link }"
                      class="break-spaces"
                      >{{ val2.name }}</router-link
                    >
                  </li>
                </template>
              </ul>
            </li>
            <li v-else-if="!(val.child || []).length && val.isPermit" :key="key">
              <router-link
                :to="{ name: val.link }"
                :title="val.name"
                class="waves-effect waves-dark"
                aria-expanded="false"
              >
                <i :class="val.icon"></i>
                <span class="hide-menu">{{ val.name }}</span>
              </router-link>
            </li>
          </template>

          <li @click="doLogout">
            <a
              class="waves-effect waves-dark"
              href="javascript:;"
              aria-expanded="false"
            >
              <i class="far fa-circle text-danger"></i>
              <span class="hide-menu">Log Out</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import GlobalVue from "@libs/Global";
let $ = global.jQuery;

/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
export default {
  extends: GlobalVue,
  data() {
    return {
      loaded: false,
      notCollapsed: null,
      menu:[
				{ head:"Main" },
				{ name:"Dashboard", module:"Dashboard", link:"Dashboard", icon:"icon-speedometer" },
				{ name:"Pre-Registration", module:"PreRegistration", link:"PreRegistration", icon:"ti-user" },
        
        { head:"Pages" },
        {name:"Home", module:"Home", link:"Home", icon:"ti-home"},
        {name:"About KLAB", module:"AboutKlab", link:"AboutKlab", icon:"ti-align-left"},
        {name:"Race Pack", module:"RacePackCollection", link:"RacePackCollection", icon:"ti-package"},
        {name:"Race Expo", module:"RaceExpo", link:"RaceExpo", icon:"ti-blackboard"},
        {name:"Race Guide", module:"RaceGuide", link:"RaceGuide", icon:"ti-agenda"},
        {name:"Insurance Protection", module:"InsuranceProtection", link:"InsuranceProtection", icon:"icon-shield"},
        {
          name: "Partner",
          icon: "icon-people",
          child: [
            { name: "Official Sponsor", module: "OfficialSponsor", link: "OfficialSponsor" },
            { name: "Media Partner", module: "MediaPartner", link: "MediaPartner" },
          ],
        },
        {name:"Blog", module:"Blog", link:"Blog", icon:"ti-book"},
        {name:"Event", module:"Event", link:"Event", icon:"ti-align-left"},
        {name:"Contact", module:"Contact", link:"Contact", icon:"icon-phone"},
        // {
        //   name: "Legal Page",
        //   icon: "ti-file",
        //   child: [
        //     { name: "Terms Conditions", module: "TermsConditions", link: "TermsConditions" },
        //     { name: "Privacy Policy", module: "PrivacyPolicy", link: "PrivacyPolicy" },
        //   ],
        // },

        { head: "Settings" },
        {name:"Web Settings", module:"WebSettings", link:"WebSettings", icon:"icon-settings"},
        {
          name: "Master Data",
          icon: "icon-folder",
          child: [
            { name: "Gallery KLAB", module: "GalleryKlab", link: "GalleryKlab" },
            { name: "KLAB Team", module: "KlabTeam", link: "KlabTeam" },
            { name: "Category Blog", module: "CategoryBlog", link: "CategoryBlog" },
            { name: "Category Partner", module: "CategoryPartner", link: "CategoryPartner" },
            { name: "Author", module: "Author", link: "Author" },
            { name: "Template", module: "Template", link: "Template" },
          ],
        },
        {
          name: "User Management",
          icon: "icon-user-follow",
          child: [
            { name: "User Level", module: "UserLevel", link: "UserLevel" },
            { name: "User List", module: "Users", link: "Users" },
          ],
        },
        // { name:"Audit Trail", module:"AuditTrail", link:"AuditTrail", icon:"icon-speedometer" },
      ],
    };
  },
  mounted() {
    $(
      ".scroll-sidebar, .right-side-panel, .message-center, .right-sidebar"
    ).perfectScrollbar();
    this.initSidebar();
  },
  computed: {
    menuData() {
      if (!this.user.id) return false;
      let menu = this.menu;
      menu.map((v) => {
        if (v.module) {
          v.isPermit = v.module
            ? this.user.menuRole.indexOf(v.module) > -1
            : true;
        } else {
          v.isPermit = v.link ? this.user.menuRole.indexOf(v.link) > -1 : true;
        }
        if (this.user.levelId == 0) v.isPermit = true;
        if (!v.link) v.link = v.module;
        if (v.child)
          v.child.map((v2) => {
            v2.isPermit = v2.module
              ? this.user.menuRole.indexOf(v2.module) > -1
              : true;
            if (this.user.levelId == 0) v2.isPermit = true;
            if (!v2.link) v2.link = v2.module;
          });
      });
      return menu;
    },
  },
  methods: {
    isShowParentMenu(val) {
      let isAnyChild = false
      if (Array.isArray(val.child) && val.child.length > 0) {
        isAnyChild = val.child.some(i => i.isPermit === true)
      }
      return (val.child || []).length && isAnyChild && val.isPermit === true
    },
    initSidebar() {
      var url = window.location;

      var element = $("ul#sidebarnav a")
        .filter(function () {
          return (url.origin + url.pathname).indexOf(this.href) > -1
        })
        .addClass("active")
        .parent()
        .addClass("active");

      while (true) {
        if (element.is("li")) {
          element = element
            .parent()
            .addClass("in")
            .parent()
            .addClass("active")
            .children("a")
            .addClass("active");
        } else {
          break;
        }
      }

      $("#sidebarnav a").on("click", function (e) {
        if (!$(this).hasClass("active")) {
          // hide any open menus and remove all other classes
          $("ul", $(this).parents("ul:first")).removeClass("in");

          $("a", $(this).parents("ul:first")).removeClass("active");

          $("#sidebarnav li").removeClass("active");

          $("#sidebarnav li a.router-link-active").removeClass(
            "router-link-exact-active"
          );

          $(this).parents().addClass("active");

          // open our new menu and add the open class
          $(this).next("ul").addClass("in");
          setTimeout(() => {
            $(this).addClass("active");
          }, 100);
        } else if ($(this).hasClass("active")) {
          if ($(this).hasClass("has-arrow")) {
            $(this).removeClass("active");
            $(this).parents().removeClass("active");
          }
          $(this).parents("ul:first").removeClass("active");
          $(this).next("ul").removeClass("in");
        }
      });

      $("#sidebarnav >li >a.has-arrow").on("click", function (e) {
        e.preventDefault();
      });
    },
  },
  watch: {
    $route(to, from) {
      let url = window.location;

      let href;

      if (process.env.NODE_ENV === "production") {
        href = url.origin + "/backoffice" + to.path;
      } else {
        href = url.origin + to.path;
      }

      let element = $("ul#sidebarnav a").filter(function () {
        return href.indexOf(this.href) > -1;
      });

      setTimeout(() => {
        element.addClass("active");
      }, 100);
    },
  },
};
</script>