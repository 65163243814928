<template>
  <header class="topbar">
    <b-navbar type="dark" toggleable="md" class="top-navbar text-center">
      <!-- ============================================================== -->
      <!-- Logo -->
      <!-- ============================================================== -->
      <div class="navbar-header pl-0">
        <b-navbar-brand to="/">
          <!-- Logo Icon -->
          <b class="text-center">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo Icon -->
            <img id="logoIcon" :src="baseUrl+'/img/logo/only-logo.png'" alt="homepage" class="dark-logo" height="54" />
            
            <!-- Light Logo Icon -->
          </b>
          <!--End of Logo Icon -->
          
          <span class="d-none d-xl-inline">
            <!-- Logo Text -->
            <img id="logoText" :src="baseUrl+'/img/logo/only-text.png'" alt="homepage" class="dark-logo logo_custom_nav ml-0 mx-2" />
            <!-- End of Logo Text -->
          </span>
        </b-navbar-brand>
      </div>
      <!-- ============================================================== -->
      <!-- End of Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse">
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->
        <b-navbar-nav class="mr-auto">
          <b-nav-item @click.prevent="toggleSidebar" link-classes="toggle-nav" class="ml-3">
            <i class="icon-menu text-white sidebartoggler"></i>
          </b-nav-item>
        </b-navbar-nav>
        <!-- ============================================================== -->
        <!-- User profile and search -->
        <!-- ============================================================== -->
        <ul class="navbar-nav my-lg-0">
          <!-- ============================================================== -->
          <!-- Comment -->
          <!-- ============================================================== -->
          
          <!-- <li class="nav-item right-side-toggle"> <a class="nav-link  waves-effect waves-light" href="javascript:void(0)">Backoffice Ver 6.1</a></li> -->
        </ul>
      </div>
    </b-navbar>
  </header>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
export default {
  extends: GlobalVue,
  methods: {
    toggleSidebar() {
      if (this.isMobile) {
        document.querySelector('body').classList.toggle('show-sidebar')
        document.querySelector('.toggle-nav i').classList.toggle('ti-close')
      } else {
        document.querySelector('body').classList.toggle('mini-sidebar')
        document.getElementById('logoText').classList.toggle('d-none')
      }
    }
  }
}
</script>